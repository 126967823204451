<template>
    <div class="copyright-area h-100 mt-3">
            <hr />
            <div class="container">
                <div class="row d-flex justify-content-around">
                    
                    <div class="col p-3" >
                        <p class="text-14 font-weight-bold">mCom Services</p>
                        <ul class="list-unstyled text-14">
                            <slot v-for="(footer,index) in footers">
                                <li  v-if="footer.type == 'mCom Services' ">
                                    <router-link :to="{ name: 'page', params: { slug: footer.slug }}">
                                        {{ footer.title }}
                                    </router-link>
                                </li>
                            </slot>
                        </ul>
                    </div>

                    <div class="col p-3">
                        <p class="text-14 font-weight-bold">Get to Know Us</p>
                        <ul class="list-unstyled text-14">
                            <slot v-for="(footer,index) in footers">
                                <li  v-if="footer.type == 'Get to Know Us' ">
                        
                                    <router-link :to="{ name: 'page', params: { slug: footer.slug}}">
                                        {{footer.title}}
                                    </router-link>
                                </li>
                            </slot>
                        </ul>
                    </div>

                    <div class="col p-3">
                        <p class="text-14 font-weight-bold">mCom.com</p>
                        <ul class="list-unstyled text-14">
                            <slot v-for="(footer,index) in footers">
                                <li  v-if="footer.type == 'mCom.com'">
                                    <router-link :to="{ name: 'page', params: { slug: footer.slug}}">
                                        {{footer.title}}
                                    </router-link>
                                </li>
                            </slot>
                        </ul>
                    </div>

                    <div class="col p-3">
                        <p class="text-14 font-weight-bold">Customer Service</p>
                        <ul class="list-unstyled text-14">
                            <slot v-for="(footer,index) in footers">
                                <li  v-if="footer.type == 'Customer Service'">
                                    <router-link :to="{ name: 'page', params: { slug: footer.slug}}">
                                        {{footer.title}}
                                    </router-link>
                                </li>
                            </slot>
                        </ul>
                    </div>

                    <div class="col p-3">
                        <p class="text-14 font-weight-bold">In the Spotlight</p>
                        <ul class="list-unstyled text-14">
                            <slot v-for="(footer,index) in footers">
                                <li  v-if="footer.type == 'In the Spotlight'">
                                    <router-link :to="{ name: 'page', params: { slug: footer.slug}}">
                                        {{footer.title}}
                                    </router-link>
                                </li>
                            </slot>
                        </ul>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-6">
                        <div class="">
                            <a
                                :href="social.facebook"
                                target="_blank"
                                class="p-2"
                                ><i class="fab fa-facebook-f"></i
                            ></a>
                            <a
                                :href="social.twitter"
                                target="_blank"
                                class="p-2"
                                ><i class="fab fa-twitter"></i
                            ></a>
                            <a
                                :href="social.linkedin"
                                target="_blank"
                                class="p-2"
                                ><i class="fab fa-linkedin"></i
                            ></a>
                        </div>
                    </div>
                    <div class="col-md-6"></div>
                </div>

                <div class="row">
                    <div class="col-xl-12 col-lg-12">
                        <div class="copyright-text">
                            <p class="mb-0" v-if="gs.footer!=null">{{gs.footer}} </p>
                            <p class="text-14" v-html="gs.copyright">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import * as footer from "../../services/service";

export default {
    data() {
        return {
                footers: [],
                gs:{},
                social:{},
        };
    },
    mounted() {
        this.loadFooter();
    },

    methods: {
            loadFooter: async function() {
                try {
                    const response = await footer.loadFooter();
                    this.footers = response.data.footers;
                    this.gs =  response.data.gs;
                    this.social = response.data.social;
                   // console.log(this.footers);
                } catch (error) {
                    console.log(error);
                    this.flashMessage.error({
                        message: error,
                        time: 5000,
                        blockClass: 'border-0',
                        wrapperClass:'warning-bg',
                        position: "left top",
                        x: 0,
                        y: 45
                    });
                }
            },
    }
}
</script> 

<style scoped>
a{
    color: #333333;
    text-decoration: none;
    background-color: transparent;
}
@media only screen and (max-width:767px){
    .col {
        flex-basis: auto;
        flex-grow: initial;
        min-width: auto;
        max-width: max-content;
        position: relative;
        width: 50%;
    }
}

</style>
