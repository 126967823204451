import Vue from 'vue';
import Router from 'vue-router';
import Home from './components/home/Home.vue';
import * as auth from './services/service'

Vue.use(Router);

const routes = [
    {
        path: '/',
        name: 'home',
        component:Home,
        // beforeEnter(to,from,next){
        //     document.title = 'Dhulo'; 
        //     document.head.querySelector('meta[property="og:description"]').content = 'New Description';  
        //     next();

        // }
        
    },

    {
        path: '/test',
        name: 'test',
        component:()=> import('./components/home/Test.vue')
    },

    // All Categories
    {
        path: '/all-categories',
        name: 'all-categories',
        component:()=> import('./components/category/AllCategories.vue')
    },

     // Sub Category Filter
     {
        path: '/category/:category/:subcategory',
        name:'subcategory',
        component:()=> import('./components/category/SubCategory.vue')
      },

    //   Child Category Filter
    {
        path: '/category/:category/:subcategory/:childcategory',
        name:'childcategory',
        component:()=> import('./components/category/ChildCategory.vue')
    },

    // Sub child category filter
    {
        path: '/category/:category/:subcategory/:childcategory/:subchildcategory',
        name:'sub-child-category',
        component:()=> import('./components/category/SubChildCategory.vue')
    },



    {
        path: '/category',
        name: 'category',
        component:()=> import('./components/category/Category.vue')
    },

    // search

    {
        path: '/search-result',
        name: 'search-result',
    
        component:()=> import('./components/category/Searchresult.vue'),
    },

    {
        path: '/product-search',
        name: 'product-search',
        component:()=> import('./components/category/Searchresult2.vue'),
    },

      // product details

    {
        path: '/product',
        name: 'product-details',
        component:()=> import('./components/product/ProductDetails.vue')
    },
    



    {
        path: '/cart',
        name: 'cart',
        component:()=> import('./components/cart/Cart.vue')
    },
    // Compare
    {
        path: '/compare',
        name: 'compare',
        component:()=> import('./components/product/Compare.vue')
    },

    // Wishlist
    {
        path: '/wishlist',
        name: 'wishlist',
        component:()=> import('./components/product/Wishlist.vue')
    },



    // login

    {
        path: '/login',
        name: 'login',
        component:()=> import('./components/auth/Login.vue'),
        beforeEnter(to,from,next){
            if(!auth.isLoggedIn()){
                next();
            }else{
                next('/user/dashboard');
            }
        }
    },

    
    {
        path: '/register',
        name: 'register',
        component:()=> import('./components/auth/Register.vue'),
        beforeEnter(to,from,next){
            if(!auth.isLoggedIn()){
                next();
            }else{
                next('/user/dashboard');
            }
        }
    },


    //Vendor login and registrations
    {
        path: '/sell',
        name: 'sell',
        component:()=> import('./components/auth/Sell.vue')
    },

    {
        path: '/selleremailverify/:tuid/:tuemail',
        name: 'selleremailverify',
        component:()=> import('./components/auth/SellerEmailVerify.vue')
    },

    {
        path: '/sellerinformation/:tuid/:tuemail',
        name: 'sellerinformation',
        component:()=> import('./components/auth/SellerInformation.vue')
    },

    //End vendor login & registrations

    // vendor route start
    {
        path: '/store-home',
        name: 'store-home',
        component:()=> import('./components/store/Home.vue'),
    },
    // vendor route end


    // checkout

    {
        path: '/checkout',
        name: 'checkout',
        component:()=> import('./components/checkout/Checkout.vue')
    },

       // Track Order
       {
        path: '/track-order',
        name: 'track-order',
        component:()=> import('./components/checkout/TrackOrder.vue')
    },

      // Track Order
      {
        path: '/test-paypal-checkout',
        name: 'test-paypal-checkout',
        component:()=> import('./components/checkout/TestPaypalCheckout.vue')
    },

      // Wallets
      {
        path: '/wallets',
        name: 'wallets',
        component:()=> import('./components/user/Wallets.vue')
    },

     // Tickets
     {
        path: '/applyasvendor',
        name: 'applyasvendor',
        component:()=> import('./components/user/ApplyAsVendor.vue')
    },


    // Tickets
    {
        path: '/tickets',
        name: 'tickets',
        component:()=> import('./components/user/Ticket.vue')
    },

    // Ticket details

    {
        path: '/view-ticket/:id',
        name: 'view-ticket',
        component:()=> import('./components/user/ViewTicket.vue')
    },

    // Disputes
    {
        path: '/disputes',
        name: 'disputes',
        component:()=> import('./components/user/Dispute.vue')
    },


    // Invoice

    {
        path: '/invoice/:id',
        name: 'invoice',
        component:()=> import('./components/checkout/Invoice.vue')
    },

     // Invoice stripe

    {
        path: '/invoice-stripe/:id',
        name: 'invoice-stripe',
        component:()=> import('./components/checkout/InvoiceStripe.vue')
    },

    // View Order

    {
        path: '/view-order/:id',
        name: 'view-order',
        component:()=> import('./components/user/ViewOrder.vue')
    },


    //User

    {
        path: '/user/dashboard',
        name: 'dashboard',
        component:()=> import('./components/user/Dashboard.vue'),
        beforeEnter(to,from,next){
            if(!auth.isLoggedIn()){
                next('/login');
            }else{
                next();
            }
        }
    },

    // purchased iteams
    {
        path: '/user/dashboard/purchased-items',
        name: 'purchase',
        component:()=> import('./components/user/Purchase.vue'),
        beforeEnter(to,from,next){
            if(!auth.isLoggedIn()){
                next('/login');
            }else{
                next();
            }
        }
    },

    // Order tracking
    {
        path: '/user/dashboard/track-order',
        name: 'track-user-order',
        component:()=> import('./components/user/TrackOrder.vue'),
        beforeEnter(to,from,next){
            if(!auth.isLoggedIn()){
                next('/login');
            }else{
                next();
            }
        }
    },

    //User

    {
        path: '/user/editprofile',
        name: 'editprofile',
        component:()=> import('./components/user/EditProfile.vue'),
        beforeEnter(to,from,next){
            if(!auth.isLoggedIn()){
                next('/login');
            }else{
                next();
            }
        }
    },

    //Venor Pending
    {
        path: '/user/vendor-pending',
        name: 'vendor-pending',
        component:()=> import('./components/user/VendorPending.vue'),
        beforeEnter(to,from,next){
            if(!auth.isLoggedIn()){
                next('/login');
            }else{
                next();
            }
        }
    },

    // User Suspend
    {
        path: '/user/suspend',
        name: 'user-suspend',
        component:()=> import('./components/user/UserSuspend.vue'),
        beforeEnter(to,from,next){
            if(!auth.isLoggedIn()){
                next('/login');
            }else{
                next();
            }
        }
    },

    //Dashboard

    {
        path: '/user/changepass',
        name: 'changepass',
        component:()=> import('./components/user/ResetPassword.vue'),
        beforeEnter(to,from,next){
            if(!auth.isLoggedIn()){
                next('/login');
            }else{
                next();
            }
        }
    },

    // Footer Link
    {
        path: '/page/:slug',
        name: 'page',
        component:()=> import('./components/footer/FooterLink.vue'),
    },

     // Reset password
    {
        path: '/reset-password-code',
        name: 'reset-password-code',
        component:()=> import('./components/auth/ForgotPassword.vue')
    },

    {
        path: '/reset-password',
        name: 'reset-password',
        component:()=> import('./components/auth/SetPassword.vue')
    },
];


// const router = new VueRouter({
//     routes: [
//       // dynamic segments start with a colon
//       { path: '/user/:id', component: User, name: 'user' }
//     ]
//   });

const router = new Router({
    routes:routes,
    
    
});

export default router;
