import {http, httpFile, httpWeb} from './http_service';
import jwt from 'jsonwebtoken';
import store from '../store';

// Menu
export function loadMenu(){
    return http().get('/menu');
}

export function langSelect(id){
    return http().get(`/lang-select/${id}`);
}

export function currencySelect(id){
    return http().get(`/currency-select/${id}`);
}

export function loadService(){
    return http().get('/service');
}

// Footer

export function loadFooter(){
    return http().get('/footer'); 
}

export function loadFooterDetails(id){
    return http().get(`/footer-details/${id}`);
}



// Poduct Details
export function loadProduct(id){
    return http().get(`/product-details/${id}`);
}

// store product
export function loadStoreProduct(id){
    return http().get(`/store-product/${id}`);
}

// Create Order
export function createOrder(data,cartInfo){
    return httpFile().post('/new-order',data);
}
// Track Order
export function trackOrder(id){
    return httpFile().post('/track-order',id);
}


// Billing Address
export function loadBillingAddress(){
    return http().get('/billing-address');
}

// Country 
export function loadCountry(){
    return http().get('/country');
}

// State
export function loadState(id){
    return httpFile().post('/load-state',id);
}

// City
export function loadCity(id){
    return httpFile().post('/load-city',id);
}

// Service point
export function loadServicePoint(id){
    return http().get(`/service-point/${id}`);
}


// Order Info
export function loadOrderInfo(id){
    return http().get(`/order-info/${id}`);
}


// Currency 
export function loadCurrency(){
    return http().get('/currency');
}

// Category filter
export function loadCategoryFilter(id,page){
    return http().get(`/category-filter/${id}?page=${page}`);
}

// Category filter
export function loadSearchFilter(keyword,page){
    return http().get(`/search-filter/${keyword}?page=${page}`);
}

// Category filter by atribute
export function loadCategoryFilterByAttribute(catId, attributeOptions, minPrice, maxPrice,sortBy,page){
    return http().post(`/category-filter-by-attri?page=${page}`,{'catId':catId,'attributeOptions':attributeOptions,'minPrice':minPrice, 'maxPrice':maxPrice,'sortBy':sortBy});
}


// Category filter by atribute
export function loadSearchFilterByAttribute(attributeOptions, minPrice, maxPrice,sortBy,keyword,page){
    return http().post(`/search-filter-by-attri?page=${page}`,{'attributeOptions':attributeOptions,'minPrice':minPrice, 'maxPrice':maxPrice,'sortBy':sortBy,'keyword':keyword});
}

// Sub Category filter
export function loadSubCategoryFilter(catId,subCatId,page){
    return http().get(`/subcategory-filter/${catId}/${subCatId}?page=${page}`);
}

// Subcategory filter by atribute
export function loadSubCategoryFilterByAttribute(catId,subCatId,attributeOptions,minPrice,maxPrice,sortBy,page){
    return http().post(`/subcategory-filter-by-attri?page=${page}`,{'catId':catId,'subCatId':subCatId,'attributeOptions':attributeOptions,'minPrice':minPrice, 'maxPrice':maxPrice,'sortBy':sortBy});
}


// child Category Filter
export function loadChildCategoryFilter(catId,subCatId,ChildId,page){
    return http().get(`/childcategory-filter/${catId}/${subCatId}/${ChildId}?page=${page}`);
}

// Childcategory filter by atribute
export function loadChildCategoryFilterByAttribute(catId,subCatId,childCatId,attributeOptions,minPrice, maxPrice,sortBy,page){
    return http().post(`/childcategory-filter-by-attri?page=${page}`,{'catId':catId,'subCatId':subCatId,'childCatId':childCatId,'attributeOptions':attributeOptions, 'minPrice':minPrice, 'maxPrice':maxPrice,'sortBy':sortBy});
}

//Sub child Category Filter
export function loadSubChildCategoryFilter(catId,subCatId,ChildId,subChildId,page){
    return http().get(`/sub-childcategory-filter/${catId}/${subCatId}/${ChildId}/${subChildId}?page=${page}`);
}

// Subchildcategory filter by atribute
export function loadSubChildCategoryFilterByAttribute(catId,subCatId,childCatId,subChildCatId,attributeOptions,minPrice,maxPrice,sortBy,page){
    return http().post(`/childcategory-filter-by-attri?page=${page}`,{'catId':catId,'subCatId':subCatId,'childCatId':childCatId,'subChildCatId':subChildCatId, 'attributeOptions':attributeOptions,'minPrice':minPrice,'maxPrice':maxPrice,'sortBy':sortBy});
}


// All Category
export function loadAllCategories(id){
    return http().get('/all-categories');
}



// wholesell inquiry
export function wholesellInquiry(data){
    return http().post('/wholesell-inquiry',data);
}

// User registration
export function createUser(data){
    return http().post('auth/register',data)
    .then(response =>{
        if(response.status === 200){
            setToken(response.data);
        }
        return response.data;
    });
}

// User registration
export function createVendor(data){
    return http().post('auth/vendor-register',data);
}

// User registration
export function verifyEmail(data){
    return http().post('auth/verify-email',data);
}

// User registration
export function completeVendorRegis(data){
    return http().post('auth/complete-vendor-regis',data)
    .then(response =>{
        if(response.status === 200){
            setToken(response.data);
        }
        return response.data;
    });
}

// User + vendor login
export function userLogin(data){
    return http().post('auth/login',data)
    .then(response =>{
        if(response.status === 200){
            setToken(response.data);
        }

        return response.data;
    });
}

// Social Login
export function socialLogin(userInfo){
    return http().post('facebookLogin',userInfo)
    .then(response =>{
        if(response.status === 200){
            setToken(response.data);
        }

        return response.data;
    });
}

// Social login using google
export function googleLogin(token){
    return http().post('googleLogin',token)
    .then(response =>{
        if(response.status === 200){
            setToken(response.data);
        }
        return response.data;
    });
}


function setToken(user){
    const token = jwt.sign({user: user},'ecomjamimeherima2020');
    localStorage.setItem('ecom-token',token);

    //console.log(user.user);

    store.dispatch('authenticate',user.user);
    
}

export function isLoggedIn(){
    const token = localStorage.getItem('ecom-token');
    return token != null;
}


export function logout(){
    http().get('/auth/logout');
    localStorage.removeItem('ecom-token');
}


export function getAccessToken(){
    const token = localStorage.getItem('ecom-token');
    if(!token){
        return null;
    }

    const tokenData = jwt.decode(token);
    return tokenData.user.access_token;
}

export function loadWallets(page){
    return http().get('/auth/wallets?page='+page);
}

export function loadTicket(page){
    return http().get('/auth/tickets?page='+page);
}

// Ticket Info
export function loadTicketInfo(id){
    return http().get(`/auth/ticket-info/${id}`);
}

export function msgSend(data){
    return httpFile().post('/auth/msg-send',data);
}

export function createTicket(data){
    return httpFile().post('/auth/create-ticket',data);
}

export function loadDispute(page){
    return http().get('/auth/disputes?page='+page);
}


export function getProfile(){
    return http().get('/auth/profile');
}

export function loginVendor(data){
    return httpWeb().post('/vendor/vue-to-lara-app',data);
}

export function editProfile(data){
    return http().post('/auth/editprofile',data);
}

export function changePassword(data){
    return http().post('/auth/changepass',data);
}

export function dashboardInfo(){
    return http().get('auth/dashboard-info');
}

export function applyForVendor(data){
    return httpFile().post('/auth/applyForVendor',data);
}

// Cupon
export function coupon(data){
    return http().post('/coupon',data);
}


// Product search

export function autoSearch(slug){
    return http().get(`/autosearch/product/${slug}`);
}


// Send code password
export function checkUserAndSendCode(data){
    return http().post('/send-code',data);
}

// Reset password
export function verifyCodeAndSetPassword(data){
    return http().post('/reset-password',data);
}

