import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import FlashMessage from '@smartweb/vue-flash-message';
import VueHtmlToPaper from 'vue-html-to-paper';
import moment from 'moment';
import VueSocialSharing from 'vue-social-sharing';
import GoogleSignInButton from 'vue-google-signin-button-directive';

Vue.filter('myDate',function(created){
    //return moment(created).format('MMMM DO YYYY');
    return  moment(created).format('MMMM Do YYYY');
});

const options = {
    name: '_blank',
    specs: [
      'fullscreen=yes',
      'titlebar=yes',
      'scrollbars=yes'
    ]
  }
  
Vue.use(VueHtmlToPaper, options);
Vue.use(FlashMessage);

window.Event = new Vue();


Vue.use(VueSocialSharing, {
  networks: {
    fakeblock: 'https://fakeblock.com/share?url=@url&title=@title'
  }
})






new Vue({
    el: '#app',
    router,
    store,
    GoogleSignInButton,
    render:h => h(App),
});
