<template>
    <main>
        <!--Header start -->
        <Header></Header>
        <!--Header end -->

        <!-- Body section start -->
        <router-view></router-view>
        <go-top bg-color="#ff5500"></go-top>
        <Footer></Footer>
        <!--Body section end -->
    </main>
</template>

<script>
import Header from './components/layout/Header.vue';
import Footer from './components/layout/Footer.vue';
import GoTop from '@inotom/vue-go-top';
import * as auth from './services/service';


export default {
    name:'App',
    beforeCreate:async function(){
        try{
            if(auth.isLoggedIn()){
                const response = await auth.getProfile();
                this.$store.dispatch('authenticate', response.data);
            }
        }catch(error){
            auth.logout();
        }
    },

    components: {
        Header,
        Footer,
        GoTop 
    },

    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {
        
    }
};
</script>

<style></style>
