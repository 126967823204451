<template>
    <hooper :settings="hooperSettings" class="container-fluid container-fluid-90">
        <slide v-for="(slider,index) in sliders" :key="index">
            <div class="p-0" :style="'background-image: url(assets/images/sliders/'+slider.photo+'); background-size:cover; width:100%'">
                <div class="container-fluid container-fluid-90 p-0">
                    <div class="row m-0">
                        <div class="col-lg-12 p-0">
                            <div class="slider-content">
                                <!-- layer 1 -->
                                <div :class="'text-'+slider.position">
                                    <!-- subtitle -->
                                    <slot v-if="slider.subtitle_anime == 'fadeIn'">
                                        <p :style="'font-size: '+ slider.subtitle_size +'px; color: ' + slider.subtitle_color +';font-weight: 700;'" :class="{'fadeIn animated': animated}">{{slider.subtitle_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.subtitle_anime == 'fadeInDown'">
                                        <p :style="'font-size: '+ slider.subtitle_size +'px; color: ' + slider.subtitle_color +';font-weight: 700;'" :class="{'fadeInDown animated': animated}">{{slider.subtitle_text}}</p>
                                    </slot>

                                    <slot  v-else-if="slider.subtitle_anime == 'fadeInLeft'">
                                        <p :style="'font-size: '+ slider.subtitle_size +'px; color: ' + slider.subtitle_color +';font-weight: 700;'" :class="{'fadeInLeft animated': animated}">{{slider.subtitle_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.subtitle_anime == 'fadeInRight'">
                                        <p :style="'font-size: '+ slider.subtitle_size +'px; color: ' + slider.subtitle_color +';font-weight: 700;'" :class="{'fadeInRight animated': animated}">{{slider.subtitle_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.subtitle_anime == 'fadeInUp'">
                                        <p :style="'font-size: '+ slider.subtitle_size +'px; color: ' + slider.subtitle_color +';font-weight: 700;'" :class="{'fadeInUp animated': animated}">{{slider.subtitle_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.subtitle_anime == 'flip'">
                                        <p :style="'font-size: '+ slider.subtitle_size +'px; color: ' + slider.subtitle_color +';font-weight: 700;'" :class="{'flip animated': animated}">{{slider.subtitle_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.subtitle_anime == 'flipInX'">
                                        <p :style="'font-size: '+ slider.subtitle_size +'px; color: ' + slider.subtitle_color +';font-weight: 700;'" :class="{'flipInX animated': animated}">{{slider.subtitle_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.subtitle_anime == 'flipInY'">
                                        <p :style="'font-size: '+ slider.subtitle_size +'px; color: ' + slider.subtitle_color +';font-weight: 700;'" :class="{'flipInY animated': animated}">{{slider.subtitle_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.subtitle_anime == 'slideInUp'">
                                        <p :style="'font-size: '+ slider.subtitle_size +'px; color: ' + slider.subtitle_color +';font-weight: 700;'" :class="{'slideInUp animated': animated}">{{slider.subtitle_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.subtitle_anime == 'slideInDown'">
                                        <p :style="'font-size: '+ slider.subtitle_size +'px; color: ' + slider.subtitle_color +';font-weight: 700;'" :class="{'slideInDown animated': animated}">{{slider.subtitle_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.subtitle_anime == 'slideInLeft'">
                                        <p :style="'font-size: '+ slider.subtitle_size +'px; color: ' + slider.subtitle_color +';font-weight: 700;'" :class="{'slideInLeft animated': animated}">{{slider.subtitle_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.subtitle_anime == 'slideInRight'">
                                        <p :style="'font-size: '+ slider.subtitle_size +'px; color: ' + slider.subtitle_color +';font-weight: 700;'" :class="{'slideInRight animated': animated}">{{slider.subtitle_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.subtitle_anime == 'rollIn'">
                                        <p :style="'font-size: '+ slider.subtitle_size +'px; color: ' + slider.subtitle_color +';font-weight: 700;'" :class="{'rollIn animated': animated}">{{slider.subtitle_text}}</p>
                                    </slot>
                                    <slot v-else>
                                        <p :style="'font-size: '+ slider.subtitle_size +'px; color: ' + slider.subtitle_color +';font-weight: 700;'">{{slider.subtitle_text}}</p>
                                    </slot>


    
                                    <!-- title -->
                                    <slot v-if="slider.title_anime == 'fadeIn'">
                                        <h2 :style="'font-size: '+ slider.title_size +'px; color: ' + slider.title_color +';font-weight: 700;'" :class="{'fadeIn animated': animated}">{{slider.title_text}}</h2>

                                    </slot>

                                    <slot v-else-if="slider.title_anime == 'fadeInDown'">
                                        <h2 :style="'font-size: '+ slider.title_size +'px; color: ' + slider.title_color +';font-weight: 700;'" :class="{'fadeInDown animated': animated}">{{slider.title_text}}</h2>
                                    </slot>

                                    <slot  v-else-if="slider.title_anime == 'fadeInLeft'">
                                        <h2 :style="'font-size: '+ slider.title_size +'px; color: ' + slider.title_color +';font-weight: 700;'" :class="{'fadeInDown animated': animated}">{{slider.title_text}}</h2>
                                    </slot>

                                    <slot v-else-if="slider.title_anime == 'fadeInRight'">
                                        <h2 :style="'font-size: '+ slider.title_size +'px; color: ' + slider.title_color +';font-weight: 700;'" :class="{'fadeInRight animated': animated}">{{slider.title_text}}</h2>
                                    </slot>

                                    <slot v-else-if="slider.title_anime == 'fadeInUp'">
                                        <h2 :style="'font-size: '+ slider.title_size +'px; color: ' + slider.title_color +';font-weight: 700;'" :class="{'fadeInUp animated': animated}">{{slider.title_text}}</h2>
                                    </slot>

                                    <slot v-else-if="slider.title_anime == 'flip'">
                                        <h2 :style="'font-size: '+ slider.title_size +'px; color: ' + slider.title_color +';font-weight: 700;'" :class="{'flip animated': animated}">{{slider.title_text}}</h2>
                                    </slot>

                                    <slot v-else-if="slider.title_anime == 'flipInX'">
                                        <h2 :style="'font-size: '+ slider.title_size +'px; color: ' + slider.title_color +';font-weight: 700;'" :class="{'flipInX animated': animated}">{{slider.title_text}}</h2>
                                    </slot>

                                    <slot v-else-if="slider.title_anime == 'flipInY'">
                                        <h2 :style="'font-size: '+ slider.title_size +'px; color: ' + slider.title_color +';font-weight: 700;'" :class="{'flipInY animated': animated}">{{slider.title_text}}</h2>
                                    </slot>

                                    <slot v-else-if="slider.title_anime == 'slideInUp'">
                                        <h2 :style="'font-size: '+ slider.title_size +'px; color: ' + slider.title_color +';font-weight: 700;'" :class="{'slideInUp animated': animated}">{{slider.title_text}}</h2>
                                    </slot>

                                    <slot v-else-if="slider.title_anime == 'slideInDown'">
                                        <h2 :style="'font-size: '+ slider.title_size +'px; color: ' + slider.title_color +';font-weight: 700;'" :class="{'slideInDown animated': animated}">{{slider.title_text}}</h2>
                                    </slot>

                                    <slot v-else-if="slider.title_anime == 'slideInLeft'">
                                        <h2 :style="'font-size: '+ slider.title_size +'px; color: ' + slider.title_color +';font-weight: 700;'" :class="{'slideInLeft animated': animated}">{{slider.title_text}}</h2>
                                    </slot>

                                    <slot v-else-if="slider.title_anime == 'slideInRight'">
                                        <h2 :style="'font-size: '+ slider.title_size +'px; color: ' + slider.title_color +';font-weight: 700;'" :class="{'slideInRight animated': animated}">{{slider.title_text}}</h2>
                                    </slot>

                                    <slot v-else-if="slider.title_anime == 'rollIn'">
                                        <h2 :style="'font-size: '+ slider.title_size +'px; color: ' + slider.title_color +';font-weight: 700;'" :class="{'rollIn animated': animated}">{{slider.title_text}}</h2>
                                    </slot>
                                    <slot v-else>
                                        <h2 :style="'font-size: '+ slider.title_size +'px; color: ' + slider.title_color +';font-weight: 700;'">{{slider.title_text}}</h2>
                                    </slot>


                                    <!-- details -->
                                    <div style="display:inline-block">
                                    <slot v-if="slider.details_anime == 'fadeIn'">
                                        <p :style="'font-size: '+ slider.details_size +'px; color: ' + slider.details_color +';width: 400px;'" :class="{'fadeIn animated text': animated}">{{slider.details_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.details_anime == 'fadeInDown'">
                                        <p :style="'font-size: '+ slider.details_size +'px; color: ' + slider.details_color +';width: 400px;'" :class="{'fadeInDown animated': animated}">{{slider.details_text}}</p>
                                    </slot>

                                    <slot  v-else-if="slider.details_anime == 'fadeInLeft'">
                                        <p :style="'font-size: '+ slider.details_size +'px; color: ' + slider.details_color +';width: 400px;'" :class="{'fadeInLeft animated': animated}">{{slider.details_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.details_anime == 'fadeInRight'">
                                        <p :style="'font-size: '+ slider.details_size +'px; color: ' + slider.details_color +';width: 400px;'" :class="{'fadeInRight animated': animated}">{{slider.details_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.details_anime == 'fadeInUp'">
                                        <p :style="'font-size: '+ slider.details_size +'px; color: ' + slider.details_color +';width: 400px;'" :class="{'fadeInUp animated': animated}">{{slider.details_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.details_anime == 'flip'">
                                        <p :style="'font-size: '+ slider.details_size +'px; color: ' + slider.details_color +';width: 400px;'" :class="{'flip animated': animated}">{{slider.details_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.details_anime == 'flipInX'">
                                        <p :style="'font-size: '+ slider.details_size +'px; color: ' + slider.details_color +';width: 400px;'" :class="{'flipInX animated': animated}">{{slider.details_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.details_anime == 'flipInY'">
                                        <p :style="'font-size: '+ slider.details_size +'px; color: ' + slider.details_color +';width: 400px;'" :class="{'flipInY animated': animated}">{{slider.details_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.details_anime == 'slideInUp'">
                                        <p :style="'font-size: '+ slider.details_size +'px; color: ' + slider.details_color +';width: 400px;'" :class="{'slideInUp animated': animated}">{{slider.details_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.details_anime == 'slideInDown'">
                                        <p :style="'font-size: '+ slider.details_size +'px; color: ' + slider.details_color +';width: 400px;'" :class="{'slideInDown animated': animated}">{{slider.details_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.details_anime == 'slideInLeft'">
                                        <p :style="'font-size: '+ slider.details_size +'px; color: ' + slider.details_color +';width: 400px;'" :class="{'slideInLeft animated': animated}">{{slider.details_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.details_anime == 'slideInRight'">
                                        <p :style="'font-size: '+ slider.details_size +'px; color: ' + slider.details_color +';width: 400px;'" :class="{'slideInRight animated': animated}">{{slider.details_text}}</p>
                                    </slot>

                                    <slot v-else-if="slider.details_anime == 'rollIn'">
                                        <p :style="'font-size: '+ slider.details_size +'px; color: ' + slider.details_color +';width: 400px;'" :class="{'rollIn animated': animated}">{{slider.details_text}}</p>
                                    </slot>
                                    <slot v-else>
                                        <p :style="'font-size: '+ slider.details_size +'px; color: ' + slider.details_color +';width: 400px;'">{{slider.details_text}}</p>
                                    </slot>
                                    </div>
                                    <p v-if="slider.link"><a :href="slider.link" target="_blank" class="mybtn1"><span>Shop Now <i class="fas fa-chevron-right"></i></span></a></p>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </slide>
        <hooper-navigation slot="hooper-addons"></hooper-navigation>
        <hooper-pagination slot="hooper-addons"></hooper-pagination>
    </hooper>
</template>

<script>
import * as sliderService from "../../services/slider";
import {
    Hooper,
    Slide,
    Pagination as HooperPagination,
    Navigation as HooperNavigation
} from "hooper";

import "hooper/dist/hooper.css";
import "../../../sass/animation.scss";

export default {
    components: {
        Hooper,
        Slide,
        HooperPagination,
        HooperNavigation
    },
    data() {
        return {
            sliders: [],
            hooperSettings: {
                autoPlay: false,
                wheelControl: false,
                playSpeed: 7000,
                transition: 2000,
                infiniteScroll: true
            },
            animated: false,
        };
    },

    methods: {
        animate: function() {
            var self = this;
           // self.animated = true;
            setInterval(function() {
                self.animated = true;
                    setTimeout(function() {
                        self.animated = false;
                    }, 4000); 
            }, 7500); 


        },

        loadSlider: async function() {
            try {
                const response = await sliderService.loadSlider();
                this.sliders = response.data;
                // console.log(this.sliders);
            } catch (error) {
                this.flashMessage.error({
                    message: "Some error occured, Please refresh!",
                    time: 5000,
                    position: "left top",
                    x: 0,
                    y: 45
                });
            }
        }
    },
    mounted() {
        this.animate();
        this.loadSlider();
    }
};
</script>

<style scoped>
.hooper {
    height: 100%;
}



.slider-content{
    background-size: cover;
    height: 280px;
    display: flex;
    align-items: center;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    padding:40px;
}
.text{
    font-size: 16px;
    font-weight: 600;
    color: #143250;
    max-width: 400px;
    display: inline-block;
}

.mybtn1 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    width: 150px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50px;
    display: inline-block;
    color: #fff!important;
    background: #ff5500;
    border: 1px solid #ff5500;
    -webkit-box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.15);
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

/* .hooper{
    height: 400px!important;
} */

.text-slide-one{
    position:absolute;
    left: 0;
    text-align: center;
}
.text-slide-two{
    position:absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}



.text-slide-three{
    position:absolute;
    right: 0;
    text-align: center;
}
@media only screen and (max-width:767px){
    .slider-content {
        background-size: cover;
        height: 250px;
        display: flex;
        align-items: center;
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        padding: 40px;
    }

    .text-slide-one {
        position: absolute;
        left: 55%;
        top: 50%;
        text-align: center;
        transform: translate(-75%, -50%);
    }

    .text-slide-two{
        position: absolute;
        top: 50%;
        left: 86%;
        transform: translate(-80%, -50%);
        text-align: center;
    }

    .text-slide-three {
        position: initial;
        right: 25px;
        text-align: center;
    }



}
</style>
