<template>
    <div>
        <div class="slider mt-1">
            <slider v-if="pagesetting.slider == 1"></slider>
        </div>
        <div class="container-fluid container-fluid-90 p-0">
            <div class="row m-0">
                <!--sidebar start -->
                <div class="col-lg-3 col-xl-2 col-md-12 pl-4 pl-md-0 d-none d-xl-block d-lg-block mt-3">
                    <!--Latest Product start -->
                    <div class="p-2 bg-white border" v-if="latestproducts.length!=0">
                        <h3 class="text-14 font-weight-bolder p-3">{{lang.lang31}}</h3>
                        <div class="mt-4" v-for="(latestproduct,index) in latestproducts" :key="index">
                            <router-link :to="{ name: 'product-details',query:{p:latestproduct.slug}}" class="btn-small mr-2" data-toggle="tooltip" data-placement="top" :title="latestproduct.name">
                                <div class="row m-0 pl-2">
                                    <div class="col-md-4 p-0">
                                        <div>
                                            <a href="#" target="_self" :title="latestproduct.name">					
                                                <img data-sizes="auto" :src="'assets/images/products/'+latestproduct.photo"  class="img-fluid">
                                            </a>
                                        </div>
                                    </div>

                                    <div class="col-md-8">
                                            <a class="text-dark font-weight-bold text-14" href="#" target="_self" :title="latestproduct.name">
                                                {{latestproduct.name.slice(0,20)}}
                                            </a>
                                        <!-- <ul class="rating text-10">
                                            <li class="fas fa-star"></li>
                                            <li class="fas fa-star"></li>
                                            <li class="fas fa-star"></li>
                                            <li class="fas fa-star"></li>
                                            <li class="fas fa-star disable"></li>
                                        </ul> -->
                                        <div class="content_price price mt-4">
                                            <span class="price-new product-price">{{currency.sign}}{{(latestproduct.price * currency.value).toFixed(2)}} </span>&nbsp;&nbsp;
                                            <span class="price-old" v-if="latestproduct.previous_price">{{currency.sign}}{{(latestproduct.previous_price * currency.value).toFixed(2)}}  </span>&nbsp;
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <!--Latest Product End  -->
                    <!-- Top Rated Product Start -->
                    <slot v-if="pagesetting.top_rated == 1">
                        <div class="p-2 bg-white border mt-4" v-if="topratedproducts.length!=0">
                            <h3 class="text-14 font-weight-bolder p-3">{{lang.lang28}}</h3>
                            <div class="mt-4" v-for="(topratedproduct,index) in topratedproducts" :key="index">
                                <router-link :to="{ name: 'product-details',query:{p:topratedproduct.slug}}" class="btn-small mr-2" data-toggle="tooltip" data-placement="top" :title="topratedproduct.name">
                                    <div class="row m-0">
                                        <div class="col-md-4 p-0">
                                            <div class="pl-2">
                                                <a href="#" target="_self" :title="topratedproduct.name">					
                                                    <img data-sizes="auto" :src="'assets/images/products/'+topratedproduct.photo" class="img-fluid">
                                                </a>
                                            </div>
                                        </div>

                                        <div class="col-md-8">
                                            <a class="text-dark font-weight-bold text-14" href="#" target="_self" :title="topratedproduct.name">
                                                {{topratedproduct.name.slice(0,20)}}
                                            </a>
                                        
                                            <!-- <ul class="rating text-10">
                                                <li class="fas fa-star"></li>
                                                <li class="fas fa-star"></li>
                                                <li class="fas fa-star"></li>
                                                <li class="fas fa-star"></li>
                                                <li class="fas fa-star disable"></li>
                                            </ul> -->
                                            <div class="content_price price mt-4">
                                                <span class="price-new product-price">{{currency.sign}}{{(topratedproduct.price * currency.value).toFixed(2)}} </span>&nbsp;&nbsp;
                                                <span class="price-old" v-if="topratedproduct.previous_price">{{currency.sign}}{{(topratedproduct.previous_price * currency.value).toFixed(2)}} </span>&nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </slot>
                    <!-- Top Rated Product End -->

                    <!-- Hot product Start-->
                    <!-- <hotproduct v-if="pagesetting.hot_sale == 1"></hotproduct> -->
                    <slot v-if="pagesetting.hot_sale == 1">
                        <div class="p-2 bg-white border mt-4" v-if="hotproducts.length!=0">
                            <h3 class="text-14 font-weight-bolder p-3">{{lang.lang30}}</h3>
                            <div class="mt-4" v-for="(hotproduct,index) in hotproducts" :key="index">
                                <router-link :to="{ name: 'product-details',query:{p:hotproduct.slug}}" class="btn-small mr-2" data-toggle="tooltip" data-placement="top" :title="hotproduct.name">
                                    <div class="row m-0">
                                        <div class="col-md-4 p-0">
                                            <div class="pl-2">
                                                <a href="#" target="_self" :title="hotproduct.name">					
                                                    <img data-sizes="auto" :src="'assets/images/products/'+hotproduct.photo" class="img-fluid">
                                                </a>
                                            </div>
                                        </div>

                                        <div class="col-md-8">
                                            <a class="text-dark font-weight-bold" href="#" target="_self" :title="hotproduct.name">
                                                {{hotproduct.name.slice(0,20)}}
                                            </a> 
                                            <!-- <ul class="rating text-10">
                                                <li class="fas fa-star"></li>
                                                <li class="fas fa-star"></li>
                                                <li class="fas fa-star"></li>
                                                <li class="fas fa-star"></li>
                                                <li class="fas fa-star disable"></li>
                                            </ul> -->
                                            <div class="content_price price">
                                                <span class="price-new product-price">{{currency.sign}}{{(hotproduct.price * currency.value).toFixed(2)}} </span>&nbsp;&nbsp;
                                                <span class="price-old" v-if="hotproduct.previous_price">{{currency.sign}}{{(hotproduct.previous_price * currency.value).toFixed(2)}} </span>&nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </slot>
                    <!-- Hot Product End -->

                    <!-- Trending start -->
                    <div class="bg-white border mt-4" v-if="trendingproducts.length!=0">
                        <h3 class="text-14 font-weight-bolder p-3">{{lang.lang32}}</h3>
                        <div class="mt-4" v-for="(trendingproduct,index) in trendingproducts" :key="index">
                            <router-link :to="{ name: 'product-details',query:{p:trendingproduct.slug}}" class="btn-small mr-2" data-toggle="tooltip" data-placement="top" :title="trendingproduct.name">
                                <div class="row m-0">
                                    <div class="col-md-4 p-0">
                                        <div class="pl-2">
                                            <a href="#" target="_self" :title="trendingproduct.name">					
                                                <img data-sizes="auto" :src="'assets/images/products/'+trendingproduct.photo" class="img-fluid">
                                            </a>
                                        </div>
                                    </div>

                                    <div class="col-md-8">
                                        <a class="text-dark font-weight-bold text-14" href="#" target="_self" :title="trendingproduct.name">
                                            {{trendingproduct.name.slice(0,20)}}
                                        </a>
                                        <!-- <ul class="rating text-10">
                                            <li class="fas fa-star"></li>
                                            <li class="fas fa-star"></li>
                                            <li class="fas fa-star"></li>
                                            <li class="fas fa-star"></li>
                                            <li class="fas fa-star disable"></li>
                                        </ul> -->
                                        <div class="content_price price">
                                            <span class="price-new product-price">{{currency.sign}}{{(trendingproduct.price * currency.value).toFixed(2)}}</span>&nbsp;&nbsp;
                                            <span class="price-old" v-if="trendingproduct.previous_price">{{currency.sign}}{{(trendingproduct.previous_price * currency.value).toFixed(2)}} </span>&nbsp;
                                        </div>
                                    </div>
                                </div>
                                
                            </router-link>
                        </div>
                    </div>
                    <!-- Trendering end -->

                    <!-- Sale start -->
                    <div class="bg-white border mt-4" v-if="sales.length!=0">
                        <h3 class="text-14 font-weight-bolder p-3">{{lang.lang33}}</h3>
                        <div class="mt-4" v-for="(sale,index) in sales" :key="index">
                            <router-link :to="{ name: 'product-details', query:{p:sale.slug}}" class="btn-small mr-2" data-toggle="tooltip" data-placement="top" :title="sale.name">
                                <div class="row m-0">
                                    <div class="col-md-4 p-0">
                                        <div class="pl-2">
                                            <a href="#" target="_self" :title="sale.name">					
                                                <img data-sizes="auto" :src="'assets/images/products/'+sale.photo" class="img-fluid">
                                            </a>
                                        </div>
                                    </div>

                                    <div class="col-md-8">
                                        <a class="text-dark font-weight-bold text-14" href="#" target="_self" :title="sale.name">
                                            {{sale.name.slice(0,20)}}
                                        </a>
                                        <!-- <ul class="rating text-10">
                                            <li class="fas fa-star"></li>
                                            <li class="fas fa-star"></li>
                                            <li class="fas fa-star"></li>
                                            <li class="fas fa-star"></li>
                                            <li class="fas fa-star disable"></li>
                                        </ul> -->
                                        <div class="content_price price mt-4">
                                            <span class="price-new product-price">{{currency.sign}}{{(sale.price * currency.value).toFixed(2)}}</span>&nbsp;&nbsp;
                                            <span class="price-old" v-if="sale.previous_price">{{currency.sign}}{{(sale.previous_price * currency.value).toFixed(2)}} </span>&nbsp;
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <!-- Sale end -->

                    <!-- Why Ecommerce start -->
                    <div class="bg-white border mt-4">
                        <h3 class="text-14 font-weight-bolder p-4">
                            Why Us ?
                        </h3>
                        <div class="row m-0 mt-4" v-for="(service,index) in services" :key="index">
                            <div class="col-md-4 p-0">
                                <div class="pl-2">
                                    <img :src="'assets/images/services/'+service.photo" alt="service" class="img-60x60">
                                </div>
                            </div>
                            <div class="col-md-8">
                                <a
                                    class="text-dark font-weight-bold text-14"
                                    :href="service.link"
                                    target="_self"
                                    title="service.title"
                                >
                                    {{service.title}}
                                </a>
                                <p class="text-dark">{{service.details.slice(0,20)}}</p>
                            </div>
                        </div>
                    </div>
                    <!-- Why Ecommerce End -->
                </div>
                <!--sidebar end -->

                <!-- main section start -->
                <div class="col-lg-9 col-xl-10 col-md-12  pl-2 pr-0  mt-3">
                    <div v-if="notices!=null && notices != ''">
                        <div class="alert  notice-bg p-4 alert-dismissible fade show" role="alert">
                            <p v-for="(notice,index) in notices" :key="index">
                                {{notice.details}}
                            </p>
                            <button type="button" class="close p-4" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <!-- Your Recently Viewed Items -->
                    <div class="border bg-white p-4 pl-5 pr-5 pb-0" :class="recentView">
                        <div class="d-flex justify-content-between border-bottom">
                            <div class="d-inline-block mt-2">
                                <p class="btn-bg border pl-4 pr-4 p-2 rounded text-16">Your Recently Viewed Items</p>
                            </div>

                            <div class="d-inline-block float-right p-3">
                                <a href="#" class="previous round control" @click.prevent="slidePrevRecentView"><i class="fa fa-caret-left"></i></a>
                                <a href="#" class="next round control" @click.prevent="slideNextRecentView"><i class="fa fa-caret-right"></i></a>
                            </div>
                        </div>
                
                        <hooper :settings="hooperSettingsRecentView" ref="carouselRecentView" @slide="updateCarouselRecentView">
                            <slide v-for="(recentViewItem,index) in recentlyViewed" :key="index">
                            <div class="item h-100 ml-2 mr-2">
                                    <div class="item-img">
                                        <!-- <div class="sell-area">
                                            <slot v-if="recentViewItem.stock>0">
                                                <span class="sale" style="background-color:green">{{lang.lang79}}</span> 
                                            </slot>
                                            <slot v-else>
                                                <span class="sale" style="background-color:red">{{lang.lang78}}</span> 
                                            </slot>
                                        </div> -->
                        
                                        <div class="extra-list">
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0)">
                                                        <span class="add-to-wish" data-href="aa" data-toggle="tooltip" :title="lang.lang54" data-placement="right" v-on:click="addWishList(recentViewItem)">
                                                        <i class="fa fa-heart" ></i>
                                                    </span>
                                                    </a>
                                                </li>
                                                <!-- <li>
                                                    <span class="quick-view" rel-toggle="tooltip" title="Quick View" href="javascript:;" data-href="wsw" data-toggle="modal" data-target="#quickview" data-placement="right"> 
                                                        <i class="fa fa-eye"></i>
                                                    </span>
                                                </li> -->
                                                <li>
                                                    <a href="javascript:void(0)">
                                                        <span class="add-to-compare" data-href="#"  data-toggle="tooltip" :title="lang.lang57" data-placement="right" v-on:click="addCompareList(recentViewItem)">
                                                            <i class="fa fa-sync-alt"></i>
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        <router-link :to="{ name: 'product-details', query:{p:recentViewItem.slug}}" class="btn-small mr-2" data-toggle="tooltip" data-placement="top" :title="recentViewItem.name">
                                            <img class="img-fluid p-2" :src="'assets/images/products/'+recentViewItem.photo" alt="img"/>
                                        </router-link>
                                    </div>
                                
                                <div class="info">
                                    <!-- <div class="stars">
                                        <div class="ratings">
                                            <div class="empty-stars"></div>
                                            <div class="full-stars" style="width:100%"></div>
                                        </div>
                                    </div> -->
                                    <h4 class="price">
                                            {{currency.sign}}{{(recentViewItem.price * currency.value).toFixed(2)}} <del><small> {{currency.sign}}{{(0 * currency.value).toFixed(2)}}</small></del>
                                    </h4>
                                    <h5 class="name">
                                        {{recentViewItem.name.slice(0,20)}}
                                    </h5>
                                    <!-- <div class="item-cart-area">
                                        <span class="add-to-cart add-to-cart-btn dt-btn" v-on:click="addCart(recentViewItem)">
                                            <i class="icofont-cart"></i> {{lang.lang56}}
                                        </span>

                                        <router-link @click.native="addCart(recentViewItem)" :to="{ name: 'cart'}" class="btn-small mr-2">
                                            <span class="add-to-cart add-to-cart-btn dt-btn">
                                                <i class="icofont-cart"></i> {{lang.lang251}}
                                            </span>
                                        </router-link>
                                    </div> -->
                                </div>
                            </div>
                            </slide>
                        </hooper>
                    </div> 
                    <!-- End Recently Viewed Items -->

                    <!-- Featured Item Start-->
                    <slot v-if="pagesetting.featured == 1">
                        <div class="border bg-white p-4 pl-5 pr-5 mt-4">
                            <div class="d-inline-block mt-2">
                                <p class="btn-bg border pl-4 pr-4 p-2 rounded text-16">{{lang.lang26}}</p>
                            </div>
                            <hr>
                            
                            <div class="row m-0">
                                <div class="col-lg-3 col-xl-2 col-md-3 col-6 remove-padding" v-for="(featureditem,index) in featureditems" :key="index">
                                    <slot v-if="(index+1)%6==0">
                                        <div>
                                            <div v-if="index==5">
                                                <a :href="banner.link_1">
                                                    <img :src="'assets/images/banners/'+banner.photo_1" class="img-fluid  rounded" alt="banner">
                                                </a>
                                            </div>
                                            <div v-else-if="index==7">
                                                <a :href="banner.link_2">
                                                    <img :src="'assets/images/banners/'+banner.photo_2" class="img-fluid" alt="banner">
                                                </a>
                                            </div>
                                        </div>
                                    </slot>
                                <slot v-else>
                                    <div class="item h-100" >
                                        <div class="item-img">
                                            <!-- <div class="sell-area">
                                                <slot v-if="featureditem.stock>0">
                                                    <span class="sale" style="background-color:green">{{lang.lang79}}</span>
                                                </slot>
                                                <slot v-else>
                                                    <span class="sale" style="background-color:red">{{lang.lang78}}</span>
                                                </slot>
                                            </div> -->
                            
                                            <div class="extra-list">
                                                <ul class="p-0">
                                                    <li>
                                                        <a href="javascript:void(0)">
                                                            <span class="add-to-wish" data-href="aa" data-toggle="tooltip" :title="lang.lang54" data-placement="right" v-on:click="addWishList(featureditem)">
                                                                <i class="fa fa-heart" ></i>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <!-- <li>
                                                        <span class="quick-view" rel-toggle="tooltip" title="Quick View" href="javascript:;" data-href="wsw" data-toggle="modal" data-target="#quickview" data-placement="right"> 
                                                            <i class="fa fa-eye"></i>
                                                        </span>
                                                    </li> -->
                                                    <li>
                                                        <a href="javascript:void(0)">
                                                            <span class="add-to-compare" data-href="#"  data-toggle="tooltip" :title="lang.lang57" data-placement="right" v-on:click="addCompareList(featureditem)">
                                                                <i class="fa fa-sync-alt"></i>
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <router-link :to="{ name: 'product-details', query:{p:featureditem.slug}}" class="btn-small mr-2" data-toggle="tooltip" data-placement="top" :title="featureditem.name">
                                                <img class="img-fluid p-2" :src="'assets/images/products/'+featureditem.photo" alt=""/>
                                            </router-link>
                                        </div>
                                        
                                        <div class="info">
                                            <h4 class="price">
                                                    {{currency.sign}}{{(featureditem.price * currency.value).toFixed(2)}} <del><small> {{currency.sign}}{{(0 * currency.value).toFixed(2)}}</small></del>
                                            </h4>
                                            <h5 class="name">
                                                {{featureditem.name.slice(0,30)}}
                                            </h5>
                                            <!-- <div class="item-cart-area">
                                                <span class="add-to-cart add-to-cart-btn dt-btn" v-on:click="addCart(featureditem)">
                                                    <i class="icofont-cart"></i> {{lang.lang56}}
                                                </span>

                                                <router-link @click.native="addCart(featureditem)" :to="{ name: 'cart'}" class="btn-small mr-2">
                                                    <span class="add-to-cart add-to-cart-btn dt-btn">
                                                        <i class="icofont-cart"></i> {{lang.lang251}}
                                                    </span>
                                                </router-link>
                                            </div> -->
                                        </div>
                                    </div>
                                </slot>
                                </div>
                            </div>
                        </div>
                    </slot>
                    <!-- Featured Item End-->
                    <!-- Top Small Banner start -->
                    <slot v-if="pagesetting.small_banner == 1">
                        <div class="mt-4 bg-white p-4 pl-5 pr-5 border">
                            <hooper
                                :settings="hooperSettingsTopSmallBanner"
                                ref="carousel"
                                @slide="updateCarousel"
                            >
                                <slide v-for="(banner,index) in topsmallbanners" :key="index">
                                    <a :href="banner.link">
                                        <img :src="'assets/images/banners/'+banner.photo" class="img-fluid rounded p-2" alt="banner">
                                    </a>
                                </slide>
                            </hooper>
                        </div>
                    </slot>
                    <!-- Top Small Banner End -->


                    <!-- Best Seller Start -->
                    <div class="border bg-white p-4 pl-5 pr-5 mt-4">
                        <div class="d-flex justify-content-between border-bottom">
                            <div class="d-inline-block">
                                <button class="btn btn-bg border text-16 p-2">{{lang.lang27}}</button>
                            </div>

                            <div class="d-inline-block float-right p-3">
                                <a href="#" class="previous round control" @click.prevent="slidePrevBestSeller"><i class="fa fa-caret-left"></i></a>
                                <a href="#" class="next round control" @click.prevent="slideNextBestSeller"><i class="fa fa-caret-right"></i></a>
                            </div>
                        </div>

                        <hooper :settings="hooperSettingsBestSeller" ref="carouselBestSeller" @slide="updateCarouselBestSeller">
                            <slide v-for="(bestseller,index) in bestsellers" :key="index">
                            <div class="item h-100">
                                    <div class="item-img">
                                        <!-- <div class="sell-area">
                                            <slot v-if="bestseller.stock>0">
                                                <span class="sale" style="background-color:green">{{lang.lang79}}</span> 
                                            </slot>
                                            <slot v-else>
                                                <span class="sale" style="background-color:red">{{lang.lang78}}</span> 
                                            </slot>
                                        </div> -->
                        
                                        <div class="extra-list">
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0)">
                                                        <span class="add-to-wish" data-href="aa" data-toggle="tooltip" :title="lang.lang54" data-placement="right" v-on:click="addWishList(bestseller)">
                                                        <i class="fa fa-heart" ></i>
                                                    </span>
                                                    </a>
                                                </li>
                                                <!-- <li>
                                                    <span class="quick-view" rel-toggle="tooltip" title="Quick View" href="javascript:;" data-href="wsw" data-toggle="modal" data-target="#quickview" data-placement="right"> 
                                                        <i class="fa fa-eye"></i>
                                                    </span>
                                                </li> -->
                                                <li>
                                                    <span class="add-to-compare" data-href="#"  data-toggle="tooltip" :title="lang.lang57" data-placement="right" v-on:click="addCompareList(bestseller)">
                                                        <i class="fa fa-sync-alt"></i>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>

                                        <router-link :to="{ name: 'product-details',query:{p:bestseller.slug}}" class="btn-small mr-2" data-toggle="tooltip" data-placement="top" :title="bestseller.name">
                                            <img class="img-fluid p-2" :src="'assets/images/products/'+bestseller.photo" alt="img"/>
                                        </router-link>
                                    </div>
                                
                                <div class="info">
                                    <!-- <div class="stars">
                                        <div class="ratings">
                                            <div class="empty-stars"></div>
                                            <div class="full-stars" style="width:100%"></div>
                                        </div>
                                    </div> -->
                                    <h4 class="price">
                                            {{currency.sign}}{{(bestseller.price * currency.value).toFixed(2)}} <del><small> {{currency.sign}}{{(0 * currency.value).toFixed(2)}}</small></del>
                                    </h4>
                                    <h5 class="name">
                                        {{bestseller.name.slice(0,30)}}
                                    </h5>
                                    <!-- <div class="item-cart-area">
                                        <span class="add-to-cart add-to-cart-btn dt-btn" v-on:click="addCart(bestseller)">
                                            <i class="icofont-cart"></i> {{lang.lang56}}
                                        </span>

                                        <router-link @click.native="addCart(bestseller)" :to="{ name: 'cart'}" class="btn-small mr-2">
                                            <span class="add-to-cart add-to-cart-btn dt-btn">
                                                <i class="icofont-cart"></i> {{lang.lang251}}
                                            </span>
                                        </router-link>
                                    </div> -->
                                </div>
                            </div>
                            </slide>
                        </hooper>
                    </div>
                    <!-- Best Seller End -->

                    <!-- Large Banner start -->
                    <slot v-if="pagesetting.large_banner == 1">
                        <div class="mt-4 bg-white p-4 border" style="width:100%!important">
                            <hooper
                                :settings="hooperSettingsLargeBanner"
                                ref="carousel"
                                @slide="updateCarousel"
                            >
                                <slide v-for="(banner,index) in largebanners" :key="index">
                                    <a :href="banner.link" style="width:100%">
                                        <img :src="'assets/images/banners/'+banner.photo" alt="banner" class="img-fluid rounded p-2">
                                    </a>
                                </slide>
                            </hooper>
                        </div>
                    </slot>
                    <!-- Large Banner End -->

                    <!-- Big Save start -->
                    <div class="border bg-white p-4 pl-5 pr-5 mt-4">
                        <div class="d-inline-block border-bottom w-100">
                            <button class="btn btn-bg border text-16">{{lang.lang29}}</button>
                        </div>
                        
                        <div class="row m-0">
                            <div class="col-lg-3 col-xl-2 col-md-4 col-6 remove-padding" v-for="(bigsave,index) in bigsaves" :key="index">
                                <slot v-if="(index+1)%6==0">
                                    <div>
                                        <div v-if="index==5">
                                            <a :href="rstwobanner.link_1">
                                                <img :src="'assets/images/banners/'+rstwobanner.photo_1" class="img-fluid rounded" alt="banner">
                                            </a>
                                        </div>
                                        <div v-else-if="index==7">
                                            <a :href="rstwobanner.link_2">
                                                <img :src="'assets/images/banners/'+rstwobanner.photo_2" class="img-fluid rounded" alt="banner">
                                            </a>
                                        </div>
                                    </div>
                                </slot>
                            <slot v-else>
                                <div class="item h-100">
                                        <div class="item-img">
                                            <!-- <div class="sell-area">
                                                <slot v-if="bigsave.stock>0">
                                                    <span class="sale" style="background-color:green">{{lang.lang79}}</span>
                                                </slot>
                                                <slot v-else>
                                                    <span class="sale" style="background-color:red">{{lang.lang78}}</span>
                                                </slot>
                                            </div> -->
                            
                                            <div class="extra-list">
                                                <ul>
                                                    <li>
                                                        <a href="javascript:void(0)">
                                                            <span class="add-to-wish" data-href="aa" data-toggle="tooltip" :title="lang.lang54" data-placement="right" v-on:click="addWishList(bigsave)">
                                                                <i class="fa fa-heart" ></i>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <!-- <li>
                                                        <span class="quick-view" rel-toggle="tooltip" title="Quick View" href="javascript:;" data-href="wsw" data-toggle="modal" data-target="#quickview" data-placement="right"> 
                                                            <i class="fa fa-eye"></i>
                                                        </span>
                                                    </li> -->
                                                    <li>
                                                        <span class="add-to-compare" data-href="#"  data-toggle="tooltip" :title="lang.lang57" data-placement="right" v-on:click="addCompareList(bigsave)">
                                                            <i class="fa fa-sync-alt"></i>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <router-link :to="{ name: 'product-details',query:{p:bigsave.slug}}" class="btn-small mr-2" data-toggle="tooltip" data-placement="top" :title="bigsave.name">
                                                <img class="img-fluid p-2" :src="'assets/images/products/'+bigsave.photo" alt=""/>
                                            </router-link>
                                        </div>
                                    
                                    <div class="info">
                                        <!-- <div class="stars">
                                            <div class="ratings">
                                                <div class="empty-stars"></div>
                                                <div class="full-stars" style="width:100%"></div>
                                            </div>
                                        </div> -->
                                        <h4 class="price">
                                                {{currency.sign}}{{(bigsave.price * currency.value).toFixed(2)}} <del><small> {{currency.sign}}{{(0 * currency.value).toFixed(2)}}</small></del>
                                        </h4>
                                        <h5 class="name">
                                            {{bigsave.name.slice(0,30)}}
                                        </h5>
                                        <!-- <div class="item-cart-area">
                                            <span class="add-to-cart add-to-cart-btn dt-btn" v-on:click="addCart(bigsave)">
                                                <i class="icofont-cart"></i> {{lang.lang56}}
                                            </span>

                                            <router-link @click.native="addCart(bigsave)" :to="{ name: 'cart'}" class="btn-small mr-2">
                                                <span class="add-to-cart add-to-cart-btn dt-btn">
                                                    <i class="icofont-cart"></i> {{lang.lang251}}
                                                </span>
                                            </router-link>
                                        </div> -->
                                    </div>
                                </div>
                            </slot>
                            </div>
                        </div>
                    </div>
                    <!-- Big Save End -->

                    <!-- Bottom Banner start -->
                    <slot v-if="pagesetting.bottom_small == 1">
                        <div class="mt-4 bg-white border p-4 pl-5 pr-5" style="width:100%!important">
                            <hooper
                                :settings="hooperSettingsBottomBanner"
                                ref="carousel"
                                @slide="updateCarousel"
                            >
                                <slide v-for="(banner,index) in bottombanners" :key="index">
                                    <a :href="banner.link" style="width:100%">
                                        <img :src="'assets/images/banners/'+banner.photo" alt="banner" class="img-fluid rounded p-2">
                                    </a>
                                </slide>
                            </hooper>
                        </div>
                    </slot>
                    <!-- Bottom Banner End -->
                </div>
                <!-- main section end -->
            </div>
            <!-- Brand Start -->
            <!-- <div class="row">
                <brand></brand>
            </div> -->
            <!-- Brand End -->
        </div>
    </div>
</template>
<script>
import Slider from "./Slider.vue";
import * as pageSetting from "../../services/pagesetting";
import {
    Hooper,
    Slide,
    Pagination as HooperPagination,
    Navigation as HooperNavigation
} from "hooper";

import "hooper/dist/hooper.css";

export default {
    components: {
        Slider,
        Hooper,
        Slide,
        HooperPagination,
        HooperNavigation
    },
    data() {
        return {
            preloader:'show',
            lang:[],
            currency:[],
            pagesetting: [],
            latestproducts:[],
            topratedproducts:[],
            hotproducts:[],
            trendingproducts:[],
            sales:[],
            services:[],
            categories:[],
            featureditems:[],
            banner:[],
            bestsellers: [],
            topsmallbanners:[],
            bigsaves:[],
            notices:[],
            rstwobanner:[],
            largebanners:[],
            bottombanners:[],
            sign: [],
            cartInfo:[],
            wishListInfo:[],
            compareListInfo:[],
            recentlyViewed: [],
            recentView:'d-none',
            // featured category slider
            carouselData: 0,
            hooperSettings: {
                itemsToShow: 2,
               // initialSlide:0,
                centerMode: false,
                autoPlay:false,
                wheelControl:false,
                infiniteScroll:false,
                breakpoints: {
                    767: {
                        centerMode: false,
                        itemsToShow: 6,
                    },
                    1000: {
                        centerMode:false,
                        itemsToShow: 6,
                    }
                }
            },
            hooperSettingsBestSeller: {
                itemsToShow: 2,
               // initialSlide:0,
                centerMode: false,
                autoPlay:false,
                wheelControl:false,
                infiniteScroll:false,
                breakpoints: {
                    767: {
                        centerMode: false,
                        itemsToShow: 4
                    },
                    1000: {
                        centerMode:false,
                        itemsToShow: 4,
                    },
                    1350: {
                        centerMode:false,
                        itemsToShow: 6,
                    }
                }
            },

            hooperSettingsRecentView: {
                itemsToShow: 2,
               // initialSlide:0,
                centerMode: false,
                autoPlay:false,
                wheelControl:false,
                infiniteScroll:false,
                breakpoints: {
                    767: {
                        centerMode: false,
                        itemsToShow: 4
                    },
                    1000: {
                        centerMode:false,
                        itemsToShow: 4,
                    },
                    1350: {
                        centerMode:false,
                        itemsToShow: 6,
                    }
                }
            },

            hooperSettingsTopSmallBanner: {
                itemsToShow: 1,
                initialSlide:0,
                centerMode: false,
                autoPlay: true,
                wheelControl: false,
                infiniteScroll: true,
                playSpeed:5000,
                transition:1000,
                breakpoints: {
                    767: {
                        centerMode: false,
                        itemsToShow: 2
                    },
                    1000: {
                        centerMode:false,
                        itemsToShow: 2,
                    }
                }
            },
            hooperSettingsLargeBanner: {
                itemsToShow: 1,
                initialSlide:0,
                centerMode: false,
                autoPlay: true,
                wheelControl: false,
                infiniteScroll: true,
                playSpeed:5000,
                transition:1000,
            },
            hooperSettingsBottomBanner: {
                itemsToShow: 1,
                initialSlide:0,
                centerMode: false,
                autoPlay: true,
                wheelControl: false,
                infiniteScroll: true,
                playSpeed:5000,
                transition:1000,
                breakpoints: {
                    767: {
                        centerMode: false,
                        itemsToShow: 3
                    },
                    1000: {
                        centerMode:false,
                        itemsToShow: 3,
                    }
                }
            }

        };
    },
    methods: {

        getRecentlyViewedItems(){

            if(localStorage.getItem('recentlyViewed')){
                this.recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed'));
                this.recentView = '';
            }

            console.log('recentlyViewed:'+this.recentlyViewed);


        },

        loadPageSetting: async function() {
            try {
                    const response = await pageSetting.loadPageSetting();
                    this.pagesetting = response.data.pagesetting;
                    this.latestproducts = response.data.latestproducts;
                    this.topratedproducts = response.data.topratedproducts;
                    this.hotproducts = response.data.hotproducts;
                    this.trendingproducts= response.data.trendingproducts;
                    this.sales = response.data.sales;
                    this.services = response.data.services;
                    this.categories = response.data.categories;
                    this.featureditems = response.data.featureditems;
                    this.banner = response.data.banner;
                    this.bestsellers = response.data.bestsellers;
                    this.topsmallbanners = response.data.topsmallbanners;
                    this.bigsaves = response.data.bigsaves;
                    this.rstwobanner = response.data.rstwobanner;
                    this.largebanners =response.data.largebanners;
                    this.bottombanners = response.data.bottombanners;
                    this.notices = response.data.notices;

                    this.preloader = 'hide';
                    localStorage.setItem('preloader', this.preloader);
                    Event.$emit('storageUpdated');
                // console.log(this.pagesetting);
            } catch (error) {
                // this.flashMessage.error({
                //     message: "Some error occured, Please refresh!",
                //     time: 5000,
                //     position: "left top",
                //     x: 0,
                //     y: 45
                // });
            }
        },
        slidePrevFeaturedCat() {
            this.$refs.carouselFeaturedCat.slidePrev();
        },
        slideNextFeaturedCat() {
            this.$refs.carouselFeaturedCat.slideNext();
        },
        updateCarouselFeaturedCat(payload) {
            this.myCarouselData = payload.currentSlide;
        },

        slidePrevBestSeller() {
            this.$refs.carouselBestSeller.slidePrev();
        },
        slideNextBestSeller() {
            this.$refs.carouselBestSeller.slideNext();
        },
        updateCarouselBestSeller(payload) {
            this.myCarouselData = payload.currentSlide;
        },

        slidePrevRecentView() {
            this.$refs.carouselRecentView.slidePrev();
        },
        slideNextRecentView() {
            this.$refs.carouselRecentView.slideNext();
        },
        updateCarouselRecentView(payload) {
            this.myCarouselData = payload.currentSlide;
        },

        updateCarousel(payload) {
            this.myCarouselData = payload.currentSlide;
        },


        addCart(product){

            if(localStorage.getItem('cartInfo')){
                this.cartInfo = JSON.parse(localStorage.getItem('cartInfo'));
            }

            // Find Duplicate
            let flag=0;

            let found = this.cartInfo.find(function(item,index){
                if(item.id === product.id){
                    item.qty = parseInt(item.qty) + 1;
                    flag=1;
                }
            });

            if(flag==0){
                this.cartInfo.push({
                    'id':product.id,
                    'user_id':product.user_id,
                    'name':product.name,
                    'photo':product.photo,
                    'measure':product.measure,
                    'price':product.price,
                    'previous_price':0,
                    'category_id':product.category_id,
                    'stock':product.stock,
                    'sku':product.sku,
                    'product_condition':product.product_condition,
                    'qty':product.min_order_qty,
                    'min_order_qty':product.min_order_qty,
                    'color': product.color,
                    'size': product.size,
                    'size_price': product.size_price,
                    'slug':product.slug
                });
            }
                
            // Put the object into storage
                localStorage.setItem('cartInfo', JSON.stringify(this.cartInfo));
            // handle event to update header cart
                Event.$emit('storageUpdated');

            this.flashMessage.success({
                message: "Successfully Added to Cart.",
                time: 0,
                blockClass: 'success-alert border-0',
                wrapperClass:'success-alert border-0',
                html:'<div class="alert alert-success alert-dismissible fade show m-0 w-100 text-center" role="alert">Successfully Added to Cart.<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>',
                position: "left top",
                x: 15,
                y: 45
            });
        },



        // Wish List start
        addWishList(product){

            if(localStorage.getItem('wishListInfo')){
                this.wishListInfo = JSON.parse(localStorage.getItem('wishListInfo'));
            }

            // Find Duplicate
            let flag=0;

            let found = this.wishListInfo.find(function(item,index){
                if(item.id === product.id){
                    flag=1;
                }
            });

            if(flag==0){
                this.wishListInfo.push({
                        'id':product.id,
                        'name':product.name,
                        'photo':product.photo,
                        'price':product.price,
                        'previous_price':0,
                        'slug':product.slug,
                        });                
                // Put the object into storage
                    localStorage.setItem('wishListInfo', JSON.stringify(this.wishListInfo));
                // handle event to update header cart
                Event.$emit('storageUpdated');

                this.flashMessage.success({
                    message: "Successfully Added to Wishlist.",
                    time: 0,
                    blockClass: 'success-alert border-0',
                    wrapperClass:'success-alert border-0',
                    html:'<div class="alert alert-success alert-dismissible fade show m-0 w-100 text-center" role="alert">Successfully Added to Wishlist.<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>',
                    position: "left top",
                    x: 15,
                    y: 45
                });
            }else{
                this.flashMessage.error({
                    message: "Already Added to Wishlist!",
                    time: 0,
                    blockClass: 'border-0',
                    wrapperClass:'warning-bg',
                    html:'<div class="alert alert-warning alert-dismissible fade show m-0 w-100 text-center" role="alert">Already Added to Wishlist!<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>',
                    position: "left top",
                    x: 15,
                    y: 45
                });
            }
        },

        // Compare list
        addCompareList(product){

            if(localStorage.getItem('compareListInfo')){
                this.compareListInfo = JSON.parse(localStorage.getItem('compareListInfo'));
            }

            // Find Duplicate
            let flag=0;

            let found = this.compareListInfo.find(function(item,index){
                if(item.id === product.id){
                    flag=1;
                }
            });

            if(flag==0){
                this.compareListInfo.push({
                        'id':product.id,
                        'name':product.name,
                        'photo':product.photo,
                        'price':product.price,
                        'size':product.size,
                        'stock':product.stock,
                        'attributes': JSON.parse(product.attributes)
                        });                
                // Put the object into storage
                    localStorage.setItem('compareListInfo', JSON.stringify(this.compareListInfo));
                // handle event to update header cart
                Event.$emit('storageUpdated');

                this.flashMessage.success({
                    message: "Successfully Added to Comapare List.",
                    time: 0,
                    blockClass: 'border-0',
                    wrapperClass:'success-bg',
                    html:'<div class="alert alert-success alert-dismissible fade show m-0 w-100 text-center" role="alert">Successfully Added to Comapare List.<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>',
                    position: "left top",
                    x: 15,
                    y: 45
                });
            }else{
                this.flashMessage.error({
                    message: "Already Added to Compare List!",
                    time: 0,
                    blockClass: 'border-0',
                    wrapperClass:'warning-bg',
                    html:'<div class="alert alert-warning alert-dismissible fade show m-0 w-100 text-center" role="alert">Already Added to Comapare List.<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>',
                    position: "left top",
                    x: 15,
                    y: 45
                });
            }
        },
    },
    mounted() {

            localStorage.setItem('preloader',this.preloader);
            Event.$emit('storageUpdated');

            // head Info
            document.title = 'Dhulo'; 

            if(localStorage.getItem('lang')){
                this.lang = JSON.parse(localStorage.getItem('lang'));
            }

            if(localStorage.getItem('currency')){
                this.currency = JSON.parse(localStorage.getItem('currency'));
            }
            this.loadPageSetting();
            
            //alert(1);
    },
    watch: {
        '$route' (to, from) {
            this.loadPageSetting();
        }
    },

    created(){
        this.getRecentlyViewedItems();
        Event.$on('storageUpdated',() => {
            if(localStorage.getItem('lang')){
                this.lang = JSON.parse(localStorage.getItem('lang'));
            }

            if(localStorage.getItem('currency')){
                this.currency = JSON.parse(localStorage.getItem('currency'));
            }
        });
    }
};
</script>

<style lang="sass">
    @import "../../../sass/product.scss"
</style>