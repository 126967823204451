<template>
    <div>
        <!-- Preloader start -->
        <div v-if="preloader== 'show'" class="preloader">
            <div class='loader'>
                <div class='loader--dot'></div>
                <div class='loader--dot'></div>
                <div class='loader--dot'></div>
                <div class='loader--dot'></div>
                <div class='loader--dot'></div>
                <div class='loader--dot'></div>
                <div class='loader--text'></div>
            </div>
        </div>
        <!-- Preloader end -->
        <div class="bg-white border-bottom">
            <div class="container-fluid container-fluid-90 p-0"> 
                <div class="row m-0">
                    <div class="col-md-6 col-sm-12">
                        <ul class="list-inline p-2 ml-auto mb-0">
                            <!-- <li class="list-inline-item">
                                <a :href="'mailto:' + setting.header_email" class="hd-text">
                                    <i class="fa fa-envelope text-14"></i>
                                    {{ setting.header_email }}
                                </a>
                            </li>

                            <li class="list-inline-item ml-1">
                                <a class="hd-text">
                                    <i class="fa fa-phone-alt text-14"></i>
                                    {{ setting.header_phone }}
                                </a>
                            </li> -->
                        </ul>
                    </div>

                    <div class="col-md-6 col-sm-12 p-0">
                        <ul class="d-flex flex-wrap justify-content-start justify-content-md-end mb-0">
                            <li class="list-group-item-nav">
                                <div class="dropdown show">
                                    <a class="nav-link hd-text dropdown-toggle" href="#" role="button" id="dropdownCurrency" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span class="text-14">
                                                {{currency.sign}} {{currency.name}}
                                            </span>
                                    </a>
                                    <!-- <div class="dropdown-menu" aria-labelledby="dropdownCurrency" style="width:100%;">
                                        <a class="dropdown-item text-14" v-for="(dollar,index) in allcurrency" :key="index" v-on:click="changeCurrency(dollar.id)">
                                            {{dollar.sign}} {{dollar.name}}
                                        </a>
                                    </div> -->
                                </div>
                            </li>

                            <li class="list-group-item-nav">
                                <div class="dropdown show">
                                    <a class="nav-link hd-text dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <!-- <img :src="'img/flag.png'" width="14" height="14" alt="Flag"/> -->
                                        <i class="fas fa-globe"></i>
                                            <span class="text-14">
                                                {{selectedLang}}
                                            </span>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style="width:100%;">
                                        <a class="dropdown-item text-14" v-for="(langcat,index) in langcategory" :key="index" v-on:click="changeLanguage(langcat.id,langcat.language)">
                                            {{langcat.language}}
                                        </a>
                                    </div>
                                </div>
                            </li>

                            <li class="list-group-item-nav" v-if="$store.state.profile.name">
                                <div class="dropdown show">
                                    <a
                                        class="nav-link hd-text dropdown-toggle"
                                        href="#"
                                        role="button"
                                        id="dropdownAccount"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <i class="fas fa-user text-14"></i> {{$store.state.profile.name}}
                                    </a>
                                
                                    <div class="dropdown-menu" aria-labelledby="dropdownAccount" style="width:100%;">
                                        <button class="dropdown-item text-14" v-on:click="myPanel()">{{lang.lang221}}</button>
                                        <button class="dropdown-item text-14" v-on:click="logout()"> {{lang.lang223}}</button>
                                    </div>
                                </div>
                            </li>

                            <li class="list-group-item-nav" v-if="!$store.state.profile.name">
                                <router-link :to="{ name: 'login'}" class="nav-link hd-text"> 
                                    <i class="fas fa-user text-14"></i>
                                    {{lang.lang12}}
                                </router-link> 
                            </li>

                            <li class="list-group-item-nav" v-if="!$store.state.profile.name">
                                <router-link :to="{ name: 'sell'}" class="nav-link hd-text"> 
                                    <i class="fas fa-user text-14"></i>
                                    {{lang.lang220}}
                                </router-link> 
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
            
        <header class="hd-bg header border-bottom" id="myHeader">
            <div class="container-fluid container-fluid-90 p-0 pt-3 pb-3">
                <div class="row m-0 mt-4">
                    <div class="col-lg-3 m-0 mmt-10 p-0 align-self-center text-center">
                        <router-link :to="{ name: 'home'}">
                            <img :src="'assets/images/'+setting.logo" class="logo">
                            <!-- <h4 class="title text-center text-lg-left">{{ setting.title }}</h4> -->
                        </router-link>
                    </div>
                    <div class="col-lg-5 mt-4 mt-lg-0 align-self-center">
                        <div class="input-group">
                            <input class="form-control my-0 py-1 lime-border text-16 dropdown-toggle" type="text"  @keyup="autoSearch" v-on:keyup.enter="enterSearch" v-model="search" :placeholder="lang.lang2"  id="dropdownSearch" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
                            <!-- <div class="dropdown-menu w-100" v-if="searchList!=''">
                                <p>{{notFound}}</p>
                            </div> -->
                            
                                <div class="dropdown-menu w-100" aria-labelledby="dropdownSearch">
                                    <a class = "dropdown-item text-14" > <i class="fa fa-search"></i>  Search suggestion</a>
                                    <a class="dropdown-item text-14" v-for="(list,index) in searchList"  v-on:click="selectSearch(list)" v-if="index < 7">
                                        {{list.name.slice(0,40)}}
                                    </a>
                                </div>
                        


                            <div class="input-group-append"  v-on:click="enterSearch">
                                <span class="input-group-text border-0 lime  btn-bg pl-4 pr-4" id="basic-text1">
                                    <i class="fas fa-search text-16" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-12 p-0 mt-4 mt-lg-0 justify-content-center align-self-center cart-content text-right">
                        <div class="d-flex justify-content-end mt-4 mt-lg-0 mb-4-lg-0 pr-4 pr-lg-0">
                            <div class="pr-2">
                                <span class="wish">
                                    <router-link :to="{ name: 'track-order'}">
                                            <i class="fas fa-truck text-14"></i>
                                            {{ lang.lang16 }}
                                    </router-link>
                                </span>
                            </div>

                            <div class="pl-4 pr-4">
                                <router-link :to="{ name: 'wishlist'}">
                                    <span class="wish">
                                        <!-- <i class="fas fa-heart fa-2x"></i> -->
                                        <img src="images/wishlist-24.png" alt="">
                                        <span class="cart-cross cart-wish" v-if="wishLists && wishLists.length > 0">{{wishLists.length}}</span>
                                    </span>
                                </router-link>
                            </div>

                            <div class="pl-4 pr-4">
                                <router-link :to="{ name: 'compare'}">
                                    <span class="wish">
                                        <!-- <i class="fa fa-sync-alt fa-2x"></i> -->
                                        <img src="images/sync.png" alt="">
                                        <span class="cart-cross cart-wish" v-if="compareLists && compareLists.length > 0">{{compareLists.length}}</span>
                                    </span>
                                </router-link>
                            </div>

                            <div class="pr-2">
                                <div class="dropdown dropdown-cart">
                                    <a  href="#" type="button" data-toggle="dropdown" class="bg-white border-0 text-16 text-dark dropdown-toggle ml-4  mr-2"  aria-haspopup="true" aria-expanded="false">
                                        <!-- <i class="fa fa-shopping-cart fa-2x cart-bg mt-3 ml-4"></i> -->
                                        <img src="images/cart-24.png" alt="">
                                        <span class="cart-cross cart-wish" v-if="cartLists && cartLists.length > 0">{{cartLists.length}}</span>
                                        <!-- MY CART  <span class="badge badge-pill badge-danger">{{cartLists.length}}</span> -->
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-cart text-14"  id="dropdownMenuCart" aria-labelledby="dropdownMenuCart">
                                        <slot v-if="cartLists.length>0">
                                            <div class="row total-header-section m-0">
                                                <div class="col-lg-6 col-sm-6 col-6">
                                                    <i class="fa fa-shopping-cart" aria-hidden="true"></i> <span class="badge badge-pill badge-danger">{{cartLists.length}}</span>
                                                    <div class="mt-4">
                                                        <router-link :to="{ name: 'cart'}" class="hd-text text-14">
                                                            {{lang.lang5}}
                                                        </router-link>

                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-sm-6 col-6 total-section text-right">
                                                    <p class="text-14">{{lang.lang6}}: <span class="text-info">{{currency.sign}}{{ (total * currency.value).toFixed(2) }}</span></p>
                                                </div>
                                            </div>

                                            <div class="row cart-detail text-14 m-0" v-for="list in cartLists">
                                                <div class="col-lg-4 col-sm-4 col-4 p-0 cart-detail-img">
                                                    <router-link :to="{ name: 'product-details',query:{p:list.slug}}" class="btn-small mr-2" data-toggle="tooltip" data-placement="top" :title="list.name">
                                                        <img :src="'assets/images/products/'+list.photo">
                                                    </router-link>
                                                </div>
                                                <div class="col-lg-8 col-sm-8 col-8 pr-0 cart-detail-product">
                                                    <div class="d-flex m-0 justify-content-between">
                                                        <router-link :to="{ name: 'product-details',query:{p:list.slug}}" class="btn-small mr-2" data-toggle="tooltip" data-placement="top" :title="list.name">

                                                        <div>
                                                            <p>{{list.name.slice(0,20)}}</p>
                                                        </div>
                                                        </router-link>
                                                        <div class="drop-cart-cross text-center">
                                                            <a href="javascript:void(0)" v-on:click="delCart(list)"><p class="text-center"><i class="fa fa-times-circle"></i></p></a>
                                                        </div>

                                                    </div>
                                                    <span class="price text-info">{{currency.sign}}{{ (list.price * currency.value).toFixed(2) }}</span> <span class="count"> {{lang.lang768}}:{{list.qty}}</span>
                                                </div>
                                            </div>
                                    
                                            <div class="row m-0">
                                                <div class="col-lg-12 col-sm-12 col-12 text-center checkout">
                                                        <slot v-if="$store.state.profile.name">
                                                            <router-link :to="{ name: 'checkout'}">
                                                                <button  type="button" class="btn btn-bg pt-3 pb-3 btn-block" style="font-size:16px;">{{lang.lang7}}</button>
                                                            </router-link>
                                                        </slot>

                                                        <slot v-else>
                                                            <button  type="button" class="btn btn-bg pt-3 pb-3 btn-block text-14" data-toggle="modal" data-target="#guestModal">{{lang.lang7}}</button>
                                                        </slot>
                                                    <!-- </router-link> -->
                                                </div>
                                            </div>
                                        </slot>
                                        <slot v-else>
                                            <div class="row m-0">
                                                <div class="col-md-12">
                                                    <p class="text-center text-14">{{lang.lang8}}</p>
                                                </div>
                                            </div>
                                        </slot>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row m-0 mt-4 d-lg-none ">
                    <div class="col-lg-12 p-0">
                        <router-link :to="{ name: 'all-categories'}" class="font-weight-700 p-2 pl-4 pr-4 pt-2 pb-4">
                            <i class="fas fa-bars"></i>  {{lang.lang1}}
                        </router-link>
                    </div>
                </div>
            </div>

                <!-- Start Navigation Bar -->
                <nav class="navbar-ecom container-fluid container-fluid-90 p-0 mt-2 mb-4  d-none d-lg-block">
                    <ul class="menu-ecom p-0 mb-0 text-14 d-flex justify-content-start">
                        <li class="pr-4 pt-2 pb-2 menu-hover text-center rounded"> 
                            <router-link :to="{ name: 'home'}" class="font-weight-700 text-center">
                                {{lang.lang17}}
                            </router-link>
                        </li>

                        <li v-for="category in categories" v-if="category.menu_order < 9 " class="pl-4 pr-4 pt-2 pb-2 menu-hover text-center rounded">
                            <a class="hasDropdown " href="#">
                                <router-link :to="{ name: 'category', params: { id: category.slug }, query:{p:category.id}}" class="font-weight-700">
                                    {{category.name}} <i class="fa fa-angle-down mt-1"></i>
                                </router-link>
                            </a>
                            <ul class="container-ecom border mt-2">
                                <!-- <div class="container__list">
                                    <div class="container__listItem">
                                        <div>Televisions</div>
                                    </div>

                                    
                                    <div class="container__listItem">
                                        <div>Refrigerators</div>
                                    </div>
                                    <div class="container__listItem">
                                        <div>Washing Machines</div>
                                    </div>
                                    <div class="container__listItem">
                                        <div>Kitchen & Home Appliances</div>
                                    </div>
                                    <div class="container__listItem">
                                        <div>Heating & Cooling Appliances</div>
                                    </div>
                                    <div class="container__listItem">
                                        <div>All Appliances</div>
                                    </div>
                                </div> -->
                                <slot v-for="(subcategory,subindex) in subcategories">
                                    <slot v-if="category.id == subcategory.category_id">
                                        <ul class="list-unstyled  text-left pl-4 pr-5 mr-2 mmt-10 pt-3" :class="{'nav-col-odd': subindex % 2 === 0, 'nav-col-even': subindex % 2 !== 0 }">
                                            <li class="p-2 menu-hover rounded">
                                                <router-link :to="{ path: '/category/'+category.slug+'/'+subcategory.slug, params: { Cat_slug: category.slug,Subcat_slug:subcategory.slug}, query:{Cat_q:category.id,Subcat_q:subcategory.id}}" class="text-16 font-weight-bold text-dark">{{subcategory.name}}</router-link>
                                            <!-- <a  href="#">{{subcategory.name}}</a> -->
                                            </li>

                                            <slot v-for="(childcategory,childindex) in childcategories">
                                                <slot v-if="subcategory.id == childcategory.subcategory_id">
                                                    <li class="p-2 menu-hover rounded">
                                                        <router-link :to="{ path: '/category/'+category.slug+'/'+subcategory.slug+'/'+childcategory.slug, params: { Cat_slug: category.slug,Subcat_slug:subcategory.slug,Child_slug:childcategory.slug}, query:{Cat_q:category.id,Subcat_q:subcategory.id,Child_q:childcategory.id}}" class="text-16 font-weight-bold text-dark">{{childcategory.name}}</router-link>
                                                    </li>

                                                    <ul class="list-unstyled pl-2 text-left">
                                                        <li v-for="(subchildcategory,subchildindex) in childcategory.subchilds" class="p-2 menu-hover rounded">
                                                            <router-link :to="{ path: '/category/'+category.slug+'/'+subcategory.slug+'/'+childcategory.slug+'/'+subchildcategory.slug, params: { Cat_slug: category.slug,Subcat_slug:subcategory.slug,Child_slug:childcategory.slug,subChild_slug:subchildcategory.slug}, query:{Cat_q:category.id,Subcat_q:subcategory.id,Child_q:childcategory.id,subChild_q:subchildcategory.id}}" class="text-dark">{{subchildcategory.name}}</router-link>
                                                        </li>
                                                    </ul>
                                                </slot>
                                            </slot>
                                        </ul>
                                    </slot>
                                </slot>
                            </ul>
                        </li>

                        <li class="pl-4 pr-4 pt-2 pb-2 menu-hover text-center rounded"> 
                            <router-link :to="{ name: 'all-categories'}" class="font-weight-700">
                                {{lang.lang1}}
                            </router-link>
                        </li>
                    </ul>
                </nav>
                <!-- End Navigation Bar -->
        </header>
        <div class="bg-danger">
            <FlashMessage position="right top"></FlashMessage>
        </div>

        <!-- New Customer modal-->
        <!-- Modal -->
        <div class="modal fade" id="guestModal" tabindex="-1" role="dialog" aria-labelledby="guestModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content border-0">
                    <div class="modal-body p-0">
                        <div class="close">
                                <button type="button" class="close bg-primary" style="font-size:28px;" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                        </div>
                        <div class="d-flex m-0">
                            <div class="w-50">
                                <div class="p-5">
                                    <h2 class="text-28">New Customer?</h2>
                                    <p class="mt-4 text-18">Create an account to:</p>

                                    <ul class="mt-5">
                                        <li class="p-3"><i class="far fa-check-circle mr-2"></i> Access your package benefits</li>
                                        <li class="p-3"><i class="far fa-check-circle mr-2"></i> Gifts card</li>
                                    </ul>

                                    <div class="mt-5 text-center">
                                        <router-link :to="{ name: 'register'}">
                                            <button type="button" data-dismiss="modal" class="btn text-center btn-bg p-3 pl-4 pr-4 mr-4 rounded">{{lang.lang749}}</button>
                                        </router-link>

                                        <!-- <router-link :to="{ name: 'checkout'}">
                                            <button type="button" class="ecom-btn dt-btn text-center p-3 pl-4 pr-4 rounded">Guest checkout</button>
                                        </router-link> -->
                                    </div>
                                </div>
                            </div>

                            <div class="w-50">
                                <div class="bg-primary d-flex h-100">
                                    <div class="align-self-center p-2 w-100 bd-highlight">
                                        <h3 class="text-center text-white text-28">Already have an account?</h3>

                                        <div class="mt-5"> 
                                            <div class="w-100 text-center pt-5">
                                                <router-link :to="{ name: 'login', params: { pathname: 'checkout' }}">
                                                    <button type="button" data-dismiss="modal" class="btn btn-light text-16 text-center p-3 pl-4 pr-4 rounded">{{lang.lang178}}</button>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import * as menu from "../../services/service";
import _ from 'lodash';
export default {
    data() {
        return {
            preloader:'show',
            selectedLang:'',
            lang: [],
            currency: [],
            allcurrency:[],
            setting: [],
            langcategory:[],
            cartLists:[],
            wishLists:[],
            compareLists:[],
            categories: [],
            subctegories: [],
            childcategories: [],
            search:'',
            searchList: [],
            notFound: '',
        };
    },
    mounted(){
        // window.onscroll = function() {myFunction()};
        // var header = document.getElementById("myHeader");
        // var sticky = header.offsetTop;

        // function myFunction() {
        //     if (window.pageYOffset > sticky) {
        //         header.classList.add("sticky");
        //     } else {
        //         header.classList.remove("sticky");
        //     }
        // }
            if(localStorage.getItem('cartInfo')){
                this.cartLists = JSON.parse(localStorage.getItem('cartInfo'));
            }
            if(localStorage.getItem('wishListInfo')){
                this.wishLists = JSON.parse(localStorage.getItem('wishListInfo')); 
            }

            if(localStorage.getItem('compareListInfo')){
                this.compareLists = JSON.parse(localStorage.getItem('compareListInfo')); 
            }
            this.loadMenu();
    },

    methods:{

        selectSearch(list) {
            this.search = list.name;
            if(list.category_id != null && list.subcategory_id != null && list.childcategory_id != null && list.subchildcategory_id != null ){

                this.$router.push({ name: 'sub-child-category', query:{Cat_q:list.category_id,Subcat_q:list.subcategory_id,Child_q:list.childcategory_id,subChild_q:list.subchildcategory_id}});

            } else if (list.category_id != null && list.subcategory_id != null && list.childcategory_id != null ) {

                this.$router.push({ name: 'childcategory', query:{Cat_q:list.category_id,Subcat_q:list.subcategory_id,Child_q:list.childcategory_id}});

            } else if (list.category_id != null && list.subcategory_id != null ) {
                this.$router.push({ name: 'subcategory', query:{Cat_q:list.category_id,Subcat_q:list.subcategory_id}});
            } else if (list.category_id != null) {
                this.$router.push({ name: 'category', query:{p:list.category_id}});
            } else {
                this.$router.push({ name: 'search-result', query:{p:list.category_id}});
            }
        },

        autoSearch: _.debounce(async function () { 
                try {
                    const response = await menu.autoSearch(this.search);
                    this.searchList = response.data.searchList;
                    this.notFound = response.data.notFound;
                } catch (error) {
                    //
                }
            }, 400),

        enterSearch: async function() {
            if(this.$router.app.$route.name == 'search-result'){
                    this.$router.push({ name: 'product-search', params:{search:this.search}});
            } else {
                    this.$router.push({ name: 'search-result', params:{search:this.search}});
            }
        },
    

        myPanel: async function(){
             this.$router.push('/user/dashboard');
        },
        logout: async function(){
            menu.logout();
            const response = {};
            this.$store.dispatch('authenticate', response);
            this.$router.push('/login');
        },

        delCart(product){
                let index =   this.cartLists.findIndex(x => x.id === product.id);
            
                    this.cartLists.splice(index, 1);

                    // Remove Local storage Item
                    localStorage.removeItem('cartInfo');

                    // Add Item To Local storage
                    localStorage.setItem('cartInfo', JSON.stringify(this.cartLists));

                    // handle event to update header cart
                    Event.$emit('storageUpdated');
                    if(this.$router.currentRoute.name=='checkout' && !this.cartLists.length >0  ){
                            this.$router.push('/cart');
                    }
        },

        loadMenu: async function() {
            try {
                const response = await menu.loadMenu();
                this.categories = response.data.categories;
                this.subcategories = response.data.subcategories;
                this.childcategories = response.data.childcategories;
                this.setting = response.data.setting;
                this.langcategory=response.data.langcategory;
                this.allcurrency = response.data.allcurrency;
                let defaultLang = '';

                let found = this.langcategory.find(function(item,index){
                    if(item.is_default == 1){
                        defaultLang = item.language;
                    }
                });
            
                // Put the language object into storage
                if(!localStorage.getItem('lang')){
                    this.lang = response.data.lang;
                    localStorage.setItem('lang', JSON.stringify(this.lang));
                    // handle event to update header
                    Event.$emit('storageUpdated');
                } else {
                        this.lang = JSON.parse(localStorage.getItem('lang'));
                }

                // Put the currency object into storage
                if(!localStorage.getItem('currency')){
                    this.currency = response.data.currency;
                    localStorage.setItem('currency', JSON.stringify(this.currency));
                    // handle event to update header
                    Event.$emit('storageUpdated');
                } else {
                        this.currency = JSON.parse(localStorage.getItem('currency'));
                }

                if(!localStorage.getItem('selectedlang')){
                    this.selectedLang = defaultLang;
                    localStorage.setItem('selectedlang', JSON.stringify(this.selectedLang));
                    // handle event to update header
                    Event.$emit('storageUpdated');
                } else {
                    this.selectedLang = JSON.parse(localStorage.getItem('selectedlang'));
                }

            } catch (error) {
                // this.flashMessage.error({
                //     message: "Some error occured, Please refresh!",
                //     time: 5000,
                //     position: "left top",
                //     x: 0,
                //     y: 45
                // });
            }
        },

        changeLanguage: async function(id,lang){
            try{
                const response = await menu.langSelect(id);
                
                 // Put the object into storage
                if(localStorage.getItem('lang')){
                    this.lang = response.data.lang;
                    localStorage.removeItem('lang');
                    localStorage.setItem('lang', JSON.stringify(this.lang));
                    // handle event to update header
                    Event.$emit('storageUpdated');
                } else {
                    this.lang = response.data.lang;
                    localStorage.setItem('lang', JSON.stringify(this.lang));
                    // handle event to update header
                    Event.$emit('storageUpdated');
                }

                 // Put the object into storage
                if(localStorage.getItem('selectedlang')){
                    localStorage.removeItem('selectedlang');
                    localStorage.setItem('selectedlang', JSON.stringify(lang));
                    // handle event to update header
                    Event.$emit('storageUpdated');
                } else {
                    localStorage.setItem('selectedlang', JSON.stringify(lang));
                    // handle event to update header
                    Event.$emit('storageUpdated');
                }
            } catch(error){
            
            }
        },

        changeCurrency: async function(id){
            try{
                const response = await menu.currencySelect(id);
                
                 // Put the object into storage
                if(localStorage.getItem('currency')){
                    this.currency = response.data.currency;
                    localStorage.removeItem('currency');
                    localStorage.setItem('currency', JSON.stringify(this.currency));
                    // handle event to update header
                    Event.$emit('storageUpdated');
                } else {
                    this.currency = response.data.currency;
                    localStorage.setItem('currency', JSON.stringify(this.currency));
                    // handle event to update header
                    Event.$emit('storageUpdated');
                }
            } catch(error){
            
            }
        }
    },

    created(){
        Event.$on('storageUpdated',() => {
            if(localStorage.getItem('preloader')){
                this.preloader = localStorage.getItem('preloader');
            } else {
                localStorage.setItem('preloader',this.preloader);
            }

            if(localStorage.getItem('currency')){
                this.currency = JSON.parse(localStorage.getItem('currency'));
            }
            else{
                this.loadMenu();
            }

            if(localStorage.getItem('lang')){
                this.lang = JSON.parse(localStorage.getItem('lang'));
            }
            else{
                this.loadMenu();
            }

            if(localStorage.getItem('selectedlang')){
                this.selectedLang = JSON.parse(localStorage.getItem('selectedlang'));
            }
            else{
                this.loadMenu();
            }

            if(localStorage.getItem('cartInfo')){
                this.cartLists = JSON.parse(localStorage.getItem('cartInfo'));
            }
            else{
                this.cartLists =[];
            }
            if(localStorage.getItem('wishListInfo')){
                this.wishLists = JSON.parse(localStorage.getItem('wishListInfo'));
            }
            else{
                this.wishLists =[];
            }

            if(localStorage.getItem('compareListInfo')){
                this.compareLists = JSON.parse(localStorage.getItem('compareListInfo'));
            }
            else{
                this.compareLists =[];
            }
            //this.cartLists = JSON.parse(localStorage.getItem('cartInfo'));
            //console.log(this.cartLists);
        });
    },

    computed: {
        total () {
            return _.sumBy(this.cartLists, function(item) {
                return (item.price * item.qty)
            })
        },
    }
};
</script>