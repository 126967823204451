import store from '../store';
import axios from 'axios';
import * as auth from './service';

export function http(){
    return axios.create({
        baseURL: store.state.apiURL,
        headers:{
            // 'Content-Type': 'multipart/form-data',
             'access-control-allow-origin': '*',
             'cache-control': 'no-cache, private',
            Authorization: 'Bearer '+auth.getAccessToken(),
        }
    });
}


export function httpFile(){
    return axios.create({
        baseURL: store.state.apiURL,
        headers:{
            Authorization: 'Bearer '+auth.getAccessToken(),
            'Content-Type': 'multipart/form-data'
        }
    });
}

export function httpWeb(){
    return axios.create({
        baseURL: store.state.webURL,
        headers:{
            Authorization: 'Bearer '+auth.getAccessToken(),
        }
    });
}
