import Vue from 'vue';
import Vuex from 'vuex';
import * as auth from './services/service';

Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        isLoggedIn: null,
        apiURL: 'http://bosoti.com/api',
        webURL: 'http://bosoti.com/',
        serverPath: 'http://bosoti.com/',
        profile: {},
    },

    mutations:{
        authenticate(state, payload){
            state.isLoggedIn = auth.isLoggedIn();
            if(state.isLoggedIn){
                state.profile = payload;
            }else{
                state.profile = {};
            }
        }
    },
    actions:{
        authenticate(context, payload){
            context.commit('authenticate',payload);
        }
    }
});
